const themeGen = theme => ({ // eslint-disable-line
  roundIcon: false,
  noBorderOnCountField: true,
  fixedCounterWidth: '50%',
  fixedCounterHeight: '32px',
  panelTopMargin: '0px',
  panelBottomMargin: '0px',
  panelWidth: '10%',
  panelWidthForPopup: '40%',
  panelWidthIpad: '17%',
  panelWidthIpadForPopup: '50%',
  panelLeftMarginMobile: '0px',
  panelLeftPaddingMobile: '10px',
  fixedIconButtonPadding: '5px',
  fontSizeCounterField: '20px',
  panelLeftPadding: '20px',
  fixedIconButtonHeight: '40px',
  fixedIconButtonWidth: '40px',
  hideInMobile: true,
  showFixedWidthQuantityCounter: true,
  readOnly: false,
});

export default themeGen;
