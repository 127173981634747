const themeGen = theme => ({  // eslint-disable-line
  showProductDetails: true,
  productNameWidth: 'ic-col-5',
  productNameWidthExceptCartPage: 'ic-col-6',
  totalColumnWidth: 'ic-col-1',
  totalColumnWidthExceptCartPage: 'ic-col-2',
  conf: {
    showPerKronaInCartTable: false,
  },
  countButtonColor: 'tertiary',
});

export default themeGen;
